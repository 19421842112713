@layer components {
	.btn {
		@apply flex items-center justify-center;
		@apply font-medium;
		@apply border border-transparent rounded-md;
		@apply rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-black;
	}

	@variants responsive {
		.btn-sm {
			@apply text-sm;
			@apply px-2 py-1;
		}

		.btn-md {
			@apply text-base;
			@apply px-4 py-2;
		}

		.btn-lg {
			@apply text-lg;
			@apply px-8 py-4;
		}

		.btn-xl {
			@apply text-xl;
			@apply px-12 py-4;
		}

		.btn-1 {
			@apply btn;
			@apply text-white;
			@apply bg-green-400 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-800;
			@apply focus:ring-green-600;
		}

		.btn-2 {
			@apply btn;
			@apply text-gray-900 dark:text-gray-100;
			@apply bg-white hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-900;
			@apply border-gray-200 dark:border-gray-800;
			@apply focus:ring-gray-600 dark:focus:ring-gray-400;
		}

		.btn-danger {
			@apply btn;
			@apply text-white;
			@apply bg-red-400 hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-800;
			@apply focus:ring-red-600;
		}
	}
}
