@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	margin: 0;
	padding: 0;
}
